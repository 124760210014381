/* src/App.css */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.home {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px; /* To prevent content from hiding behind the footer */
}
