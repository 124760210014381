/* Home.css */

.home {
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
}

.header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
    flex-wrap: wrap;
}

.header-container div {
    flex: 1;
    min-width: 300px;
    margin: 10px;
}

.header-container h1 {
    font-size: 5vw;
    margin: 0;
}

.header-container h3 {
    font-size: 2vw;
    margin: 10px 0;
}

.header-container p {
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
}

.header-container img {
    width: 100%;
    max-width: 350px;
    height: auto;
}

.projects-section {
    width: 100%;
}

.project {
    margin-top: 50px;
}

.project img {
    width: 100%;
    height: auto;

}

iframe {
    background-color: white;
    margin-top: 50px;
    margin-bottom: 50px;
}

.warGamePhoto {
    display: none;
}

.warGameGif {
    display: block;
}

.eyesTalkIframeWeb {
    width: 68%;
}

#tools4allMedia {
    display: none;
}

@media (max-width: 768px) {
    .projects-section h2 {
        margin: 10px 0;
    }

    #tools4all{
        display: none;
    }

    #tools4allMedia {
        display: block;
    }

    .warGameGif {
        display: none;
    }

    .warGamePhoto {
        display: block;
    }

    .eyesTalkIframeMedia {
        display: none;
    }

    .eyesTalkIframeWeb {
        width: 100%;
    }

    .header-container p{
        font-size:20px;
    }

    .header-container h3 {
        font-size: 30px;
    }

    .header-container h1 {
        font-size: 40px;
    }
}