/* src/Footer.css */
.footer {
    background-color: black;
    border-top: 1px solid #e7e7e7;  
    color: white;
    /* text-align: center; */
    padding-left: 30px;
    padding-top: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
}